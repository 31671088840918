<template>
  <v-row v-if="loading" align="center" justify="center" class="fill-height">
    <v-progress-circular
      :size="60"
      :width="5"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </v-row>

  <v-container v-else>
    <v-card max-width="547" class="mx-auto pt-6 pb-8 px-3 px-md-11">
      <v-form v-model="valid" @submit.prevent="submit" ref="createBusiness">
        <attachment
          :file="businessLogo"
          @pushFileUp="uploadFile"
          @remove="removeLogo"
          @notValid="validImage = false"
          class="mb-6"
        />

        <v-text-field
          name="businessName"
          v-model="businessModel.businessName"
          :rules="businessNameRules"
          required
          :label="$t('panel.businessesPage.createBusiness.businessName')"
          :hint="$t('panel.businessesPage.createBusiness.businessNameHint')"
          persistent-hint
          counter="200"
          filled
          hide-details="auto"
          class="theme-custom-input mb-6"
        >
        </v-text-field>

        <v-select
          name="businessType"
          v-model="businessModel.businessTypeId"
          item-value="businessTypeId"
          item-text="businessTypeName"
          :items="businessTypes"
          :rules="businessTypeRules"
          required
          :label="$t('panel.businessesPage.createBusiness.businessCategory')"
          filled
          class="theme-custom-input mb-6"
          hide-details="auto"
          :menu-props="{ contentClass: 'theme-custom-bigInput-dropdown-menu' }"
          append-icon="$arrowDown"
        >
          <template v-slot:selection="{ item }">
            <span class="font-regular-14 gray12--text">{{
              item.businessTypeName
            }}</span>
          </template>
          <template v-slot:item="{ item }">
            <span class="font-regular-12 gray12--text">{{
              item.businessTypeName
            }}</span>
          </template>
          <!--          <template v-slot:selection="{ item }">-->
          <!--            <span class="font-regular-12 gray12&#45;&#45;text">{{-->
          <!--              item.businessTypeName-->
          <!--            }}</span>-->
          <!--          </template>-->

          <!--          <template v-slot:item="{ active, item, attrs, on }">-->
          <!--            <template>-->
          <!--              <v-list-item v-on="on" #default>-->
          <!--                <span class="font-regular-12 gray12&#45;&#45;text">{{-->
          <!--                  item.businessTypeName-->
          <!--                }}</span>-->
          <!--              </v-list-item>-->
          <!--            </template>-->
          <!--          </template>-->
        </v-select>

        <div class="d-flex align-center mb-9">
          <span class="gray7--text me-1">
            {{ $t("panel.businessesPage.createBusiness.rate") }}
          </span>

          <v-tooltip
            v-model="showRateTooltip"
            top
            color="white"
            content-class="rate-tooltip"
            class="rate-tooltip"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                size="16"
                color="transparent"
                class="me-6"
                v-bind="attrs"
                v-on="on"
                >$info
              </v-icon>
            </template>
            <span class="font-regular-12 gray8--text">
              {{ $t("panel.businessesPage.createBusiness.rateTooltipDesc") }}
            </span>
          </v-tooltip>

          <v-btn
            outlined
            color="primary"
            width="109"
            height="30"
            back
            class="rate-btn"
          >
            <v-icon size="16" class="me-1">$check</v-icon>
            <span class="font-medium-12">
              {{ $t("panel.businessesPage.createBusiness.defaultRate") }}
            </span>
          </v-btn>
        </div>

        <v-btn
          :loading="submitLoading"
          color="primary"
          block
          depressed
          height="50"
          type="submit"
        >
          <span class="font-semiBold-14">
            {{ $t("panel.businessesPage.createBusiness.next") }}
          </span>
        </v-btn>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import { MerchantService } from "@/services";
import Attachment from "./Attachment";

const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = (event) => {
      resolve(event.srcElement.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export default {
  name: "create-business",
  components: { Attachment },
  data() {
    return {
      loading: false,
      submitLoading: false,
      valid: false,
      validImage: false,
      showRateTooltip: false,
      businessModel: {
        image: "",
        businessName: "",
        businessTypeId: "",
      },
      businessTypes: [],
      businessLogo: "",
      rules: {
        required: (v) =>
          !!v ||
          this.$t("panel.businessesPage.createBusiness.businessNameNotEmpty"),
        max: (v) =>
          (v && v >= 200) ||
          this.$t("panel.businessesPage.createBusiness.businessNameLength"),
      },
      businessNameRules: [
        (v) =>
          !!v ||
          this.$t("panel.businessesPage.createBusiness.businessNameNotEmpty"),
        (v) =>
          (v && v.length <= 200) ||
          this.$t("panel.businessesPage.createBusiness.businessNameLength"),
      ],
      businessTypeRules: [
        (v) =>
          !!v ||
          this.$t(
            "panel.businessesPage.createBusiness.businessCategoryNotEmpty"
          ),
      ],
    };
  },
  mounted() {
    this.getBusinessTypes();
  },
  methods: {
    getBusinessTypes() {
      this.loading = true;
      MerchantService.getMerchantTypes().then((res) => {
        this.businessTypes = res.data.data.content;
        this.loading = false;
      });
    },
    submit() {
      if (!this.$refs.createBusiness.validate()) {
        return;
      }
      if (!this.validImage) {
        this.$toast.error(
          this.$t("panel.businessesPage.createBusiness.imageNotUploaded")
        );
        return;
      }
      this.submitLoading = true;
      MerchantService.addMerchant(this.businessModel)
        .then((res) => {
          this.$router.replace(
            `/panel/businesses/${res.data.data.merchantId}/create-gateway`
          );
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },
    removeLogo() {
      this.businessLogo = "";
      this.businessModel.image = "";
    },
    uploadFile(file) {
      this.businessLogo = URL.createObjectURL(file);
      convertBase64(file).then((res) => {
        this.businessModel.image = res;
      });
      // this.businessModel.logo = file;
      this.validImage = true;
    },
  },
};
</script>

<style scoped lang="scss">
@import "CreateBusiness";
</style>
