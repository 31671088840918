<template>
  <div class="text-center">
    <div class="mb-3 mx-auto">
      <img v-if="file" :src="file" width="68" height="68" />

      <div v-else class="logo-bg d-flex align-center justify-center mx-auto">
        <v-icon size="36" color="gray3">$uploadArrow</v-icon>
      </div>
    </div>

    <v-btn
      outlined
      color="primary"
      :loading="isSelecting"
      @click="openUploader"
      class="upload-photo-btn px-1 py-2"
    >
      <span class="font-semiBold-12">{{ buttonText }}</span>
    </v-btn>

    <!--    <v-btn-->
    <!--      v-if="file"-->
    <!--      text-->
    <!--      color="transparent"-->
    <!--      width="51"-->
    <!--      height="18"-->
    <!--      @click="remove"-->
    <!--    >-->
    <!--      <span class="font-semiBold-14 primary&#45;&#45;text text-decoration-underline">{{-->
    <!--        $t("panel.businessesPage.createBusiness.remove")-->
    <!--      }}</span>-->
    <!--    </v-btn>-->

    <div v-if="fileRules" class="font-regular-12 error--text mt-1">
      {{ fileRules }}
    </div>

    <input
      ref="uploader"
      accept=".jpg,.jpeg,.png"
      type="file"
      class="d-none"
      @change="checkValidation"
    />
  </div>
</template>

<script>
export default {
  name: "attachment",
  props: ["file"],
  data() {
    return {
      selectedFile: undefined,
      isSelecting: false,
      fileRules: "",
      src: "",
      maxSize: process.env.VUE_APP_IMAGE_MAX_SIZE,
      minSize: process.env.VUE_APP_IMAGE_MIN_SIZE,
    };
  },
  watch: {},
  computed: {
    buttonText() {
      return this.file
        ? this.$t("panel.businessesPage.createBusiness.editLogo")
        : this.$t("panel.businessesPage.createBusiness.uploadLogo");
    },
  },
  methods: {
    openUploader() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },
    checkValidation(newFile) {
      if (!newFile) return;
      this.selectedFile = newFile.target.files[0];
      const fileSize = this.selectedFile.size / 1024;
      const isJpeg = this.selectedFile.type === "image/jpeg";
      const isJpg = this.selectedFile.type === "image/jpg";
      const isPng = this.selectedFile.type === "image/png";
      if (!isPng && !isJpeg && !isJpg) {
        this.selectedFile = undefined;
        this.fileRules = "File format is incorrect";
        this.imageNotValid();
      } else if (fileSize < this.minSize) {
        this.fileRules = "Minimum file size is 10KB";
        this.selectedFile = undefined;
        this.imageNotValid();
      } else if (fileSize > this.maxSize) {
        this.fileRules = "Maximum file size is 150KB";
        this.selectedFile = undefined;
        this.imageNotValid();
      } else this.pushFile();
    },
    pushFile() {
      this.$emit("pushFileUp", this.selectedFile);
      this.fileRules = "";
    },
    remove() {
      this.fileRules = "";
      this.selectedFile = undefined;
      this.$emit("remove");
    },
    imageNotValid() {
      this.selectedFile = undefined;
      this.$emit("remove");
      this.$emit("notValid");
    },
  },
};
</script>

<style scoped lang="scss">
@import "Attachment";
</style>
